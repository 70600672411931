<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <users-table type="dark" :title="$t('users.all users')"></users-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import RolesTable from "../Tables/User/RolesTable.vue";
import UsersTable from "../Tables/User/UsersTable.vue";
export default {
  name: "Users",
  components: {
    UsersTable,
  },
};
</script>
<style></style>
